import { CONFIG_NODE_ENV, CONFIG_APP_SITE, CONFIG_CAPTCHA_PUBLIC, CONFIG_GOOGLE_API_KEY, CONFIG_GTM_TAG_ID, CONFIG_BROKER_DATA, CONFIG_NAVIGATION  } from '../config/config'

/**
 * Enum constants
 */
//SHARED
export const NODE_ENV = CONFIG_NODE_ENV
export const BASE_URL = process.env.PUBLIC_URL
export const REACT_APP_SITE = CONFIG_APP_SITE

//REACT
//Prevents requests being unneccassarily proxied
export const REACT_APP_PROXY = "/proxy"
export const REACT_APP_CAPTCHA_PUBLIC = CONFIG_CAPTCHA_PUBLIC
export const REACT_APP_GOOGLE_API_KEY = CONFIG_GOOGLE_API_KEY
export const REACT_APP_GTM_TAG_ID= CONFIG_GTM_TAG_ID

export const ENDPOINTS = Object.freeze({
  BROKER_DATA: CONFIG_BROKER_DATA,
  NAVIGATION: CONFIG_NAVIGATION,
})

export const ROUTES = Object.freeze({
  FIND_A_BROKER: "/broker",
  BROKER_DIRECTORY: "/broker-directory",
  BROKER_PROFILE: "/broker-profile",
})

export const LANG = Object.freeze({
  EN: { CODE: "en", ID: "1" },
  FR: { CODE: "fr", ID: "102" },
})

export const PAGE_PART = Object.freeze({
  HEADER: { NAME: "HEADER", URL: "nav-mod" },
  FOOTER: { NAME: "FOOTER", URL: "footer-integration" },
})

export const FAB_VIEW = Object.freeze({
  MAP: "FAB_VIEW_MAP",
  BROKER_DIRECTORY: "FAB_VIEW_BROKER_DIRECTORY",
  PROFILE: "FAB_VIEW_PROFILE",
})

export const LOCAL_STORAGE_LANG = "dotCMSLanguage"

export const BRANCH_EMAIL = Object.freeze({
  AB: "CAWebContentMgmt@wawanesa.com",
  BC: "CAWebContentMgmt@wawanesa.com",
  MB: "CAWebContentMgmt@wawanesa.com",
  NB: "CAWebContentMgmt@wawanesa.com",
  NL: "CAWebContentMgmt@wawanesa.com",
  NT: "CAWebContentMgmt@wawanesa.com",
  NS: "CAWebContentMgmt@wawanesa.com",
  NU: "CAWebContentMgmt@wawanesa.com",
  ON: "CAWebContentMgmt@wawanesa.com",
  PE: "CAWebContentMgmt@wawanesa.com",
  QC: "CAWebContentMgmt@wawanesa.com",
  SK: "CAWebContentMgmt@wawanesa.com",
  YT: "CAWebContentMgmt@wawanesa.com",
})

export const WAWANESA_BRANCHES = Object.freeze({
  SAN_DIEGO: "San Diego",
  BRITISH_COLUMBIA: "British Columbia",
  PRAIRIE: "Prairie",
  WINNIPEG: "Winnipeg",
  NORTHERN_ALBERTA: "Northern Alberta",
  SOUTHERN_ALBERTA: "Southern Alberta",
  ONTARIO: "Ontario",
  QUEBEC: "Quebec",
  MARITIME: "Maritime",
})

import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { LangContext } from '../../../../localization/localizationContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faPhone, faRoute, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { FAB_VIEW, ROUTES } from '../../../../utils/consts'

/**
 * @summary Loads a individual search result for the Find A Broker left pane
 *
 * @param {object} props.broker Broker object from db
 * @param {boolean} props.highlighted Selected status of entry
 * @param {function} props.handleUpdateCenter Handles updating the map when a broker is selected
 */
// First object in params is props, second is context, provided by the ScrollArea component
const Result = ({ broker, highlighted, handleUpdateCenter, viewType }, { scrollArea }) => {
  const { translate } = useContext(LangContext)

  const handleScroll = scrollArea ? scrollArea.scrollYTo : null

  useEffect(() => {
    const calcDistFromTop = () => {
      let dist = 0
      const results = document.getElementsByClassName('ww-fab-list-entry')
      for (const res of results) {
        if (!res.classList.contains('active')) {
          dist += res.offsetHeight
        } else {
          break
        }
      }
      return dist
    }
    if (highlighted && handleScroll) {
      handleScroll(calcDistFromTop())
    }
  }, [highlighted, handleScroll])

  const updateCenter = () => {
    const coords = { lat: broker.position.lat, lng: broker.position.lng }
    handleUpdateCenter(coords, broker.code)
  }

  let brokerURLNoProtocol
  if (broker.website) {
    brokerURLNoProtocol = (broker.website).replace(/^https?:\/\//i, '')
  }

  const brokerPhone = broker.phone1 || broker.phone2
  const brokerAddress = `${broker.address}, ${broker.city}, ${broker.province}`
  const brokerContact = {
    phone: brokerPhone && (
      <div className="ww-fab-contact-item ww-fab-phone">
        <a href={`tel:${brokerPhone.replace(/\D/g, '')}`}>
          <FontAwesomeIcon icon={faPhone} />
          {` ${brokerPhone}`}
        </a>
      </div>
    ),
    directions: (
      <div className="ww-fab-contact-item ww-fab-directions">
        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${broker.position.lat},${broker.position.lng}`}
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faRoute} />
          {` ${translate('fab.details.directions')}`}
        </a>
      </div>
    ),
    email: broker.email && (
      <div className="ww-fab-contact-item ww-fab-contact">
        <a href={`mailto:${broker.email}`}>
          <FontAwesomeIcon icon={faAt} />
          {` ${translate('fab.details.email')}`}
        </a>
      </div>
    ),
    website: brokerURLNoProtocol && (
      <div className="ww-fab-contact-item ww-fab-link">
        <a
          href={`//${brokerURLNoProtocol}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faGlobeAmericas} />
          {` ${brokerURLNoProtocol}`}
        </a>
      </div>
    ),
  }

  let brokerResult

  switch (viewType) {
    case FAB_VIEW.BROKER_DIRECTORY:
      brokerResult = (
        <div className="ww-fa-directory-result" data-testid="fab-listing">
          <span>
            <Link to={`${ROUTES.BROKER_PROFILE}/${broker.code}`} className="ww-broker-name">
              <strong>{broker.name}</strong>
            </Link>
            <br />
            {brokerAddress}
            <br />
            {brokerContact.phone}
          </span>
        </div>
      )
      break
    case FAB_VIEW.PROFILE:
      brokerResult = (
        <div className="ww-fa-directory-result" data-testid="fab-listing">
          <span>
            <strong>{broker.name}</strong>
            <br />
            {brokerAddress}
            <br />
            {brokerContact.phone}
            {brokerContact.directions}
            {brokerContact.email}
            {brokerContact.website}
          </span>
        </div>
      )
      break
    default: // FAB_VIEW.MAP
      brokerResult = (
        <div className={`ww-fab-list-entry${highlighted ? ' active' : ''}`} data-testid="fab-listing">
          <div className="ww-fab-list-item">
            <a href="#" onClick={updateCenter}>
              <h2>{ broker.name }</h2>
            </a>
            <p className="ww-fab-broker-location">
              {`${broker.address}, ${broker.city}, ${broker.province}`}
            </p>
            <div>
              {brokerContact.phone}
              {brokerContact.directions}
              {brokerContact.email}
              {brokerContact.website}
            </div>
          </div>
        </div>
      )
  }

  return brokerResult
}

// eslint-disable-next-line react/forbid-prop-types
Result.contextTypes = { scrollArea: PropTypes.object }

export default Result

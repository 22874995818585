import React from 'react'

/**
 * @description A generic spinner component
 *
 * @param {boolean} props.large Increase the size of the spinner
 * @param {boolean} props.blue Changes spinner from grey to Wawanesa blue
 * @param {boolean} props.fullscreen Centers the spinner, enlarges it, and changes the color to blue
 */
const Loader = ({ large, blue, fullscreen }) => {
  let loaderStyles = 'loader'
  if (fullscreen) {
    loaderStyles += ' ww-blue large'
    return (
      <div className="center-screen">
        <div className={loaderStyles} />
      </div>
    )
  }
  if (large) loaderStyles += ' large'
  if (blue) loaderStyles += ' ww-blue'
  return <div className={loaderStyles} />
}

export default Loader

import { LANG, LOCAL_STORAGE_LANG } from '../utils/consts'

/**
 * @description Gets a language object based off a language id or code
 *
 * @param {object|string|number} id Language id (1|102), code (en|fr), or language object
 *
 * @returns {object|undefined} Language object or undefined
 */
const getLanguageById = (id) => {
  let safeId
  if (id) {
    if (typeof id === 'object') {
      safeId = id.CODE
    } else {
      safeId = id.toString().toLowerCase()
    }
  }
  switch (safeId) {
    case LANG.EN.CODE:
    case LANG.EN.ID.toString():
      return LANG.EN
    case LANG.FR.CODE:
    case LANG.FR.ID.toString():
      return LANG.FR
    default:
      return undefined
  }
}

/**
 * @description Gets the language, if any, specified in the language_id parameter
 *
 * @returns {object|undefined} Language object or undefined
 */
const getLanguageFromParam = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return getLanguageById(urlParams.get('language_id'))
}

/**
 * @description Gets the language, if any, specified in localStorage under dotCMSLanguage
 *
 * @returns {object|undefined} Language object or undefined
 */
const getLanguageFromLocalStorage = () => getLanguageById(localStorage.getItem(LOCAL_STORAGE_LANG))

/**
 * @description Gets the preferred language from browser settings if it is English or French
 *
 * @returns {object|undefined} Language object or undefined
 */
const getLanguageFromBrowser = () => {
  const browserLang = navigator.language || navigator.userLanguage
  // Slicing off the first 2 letters en or fr
  return getLanguageById(browserLang && browserLang.slice(0, 2))
}

/**
 * @description Saves the specified language to localStorage under dotCMSLanguage
 *
 * @param {object|string|number} id Language id (1|102), code (en|fr), or language object
 *
 * @returns {object|undefined} The language object that was set (or attempted to)
 */
export const setLanguageToLocalStorage = (lang) => {
  const desiredLang = getLanguageById(lang)
  if (desiredLang) localStorage.setItem(LOCAL_STORAGE_LANG, desiredLang.CODE)
  return desiredLang
}

/**
 * @description Gets the default language according to url params, localStorage, browser, or english
 *  and sets it to localStorage
 *
 * @returns {object} The desired language object
 */
export const getLanguage = () => {
  //  Assigns the first truthy value
  const desiredLang = getLanguageFromParam()
                   || getLanguageFromLocalStorage()
                   || getLanguageFromBrowser()
                   || LANG.EN
  return setLanguageToLocalStorage(desiredLang)
}

/* Use back ticks ` in favour of single ' or double "" quotes to have strings evaluated literally in
the case of inline quotes, apostrophes and special characters */

/* eslint-disable quotes */
const en = {
  wawanesa: `Wawanesa Insurance`,
  language: `English`,
  otherLanguage: `Français`,
  locale: `en-US`,
  common: {
    geo: {
      address: `Address`,
      city: `City`,
      country: `Country`,
      postalCode: `Postal Code`,
      zipCode: `Zip Code`,
      countries: {
        canada: `Canada`,
        usa: `United States of America`,
      },
      province: `Province`,
      provinces: {
        alberta: `Alberta`,
        britishColumbia: `British Columbia`,
        manitoba: `Manitoba`,
        newBrunswick: `New Brunswick`,
        newfoundlandAndLabrador: `Newfoundland and Labrador`,
        northwestTerritories: `Northwest Territories`,
        novaScotia: `Nova Scotia`,
        nunavut: `Nunavut`,
        ontario: `Ontario`,
        princeEdwardIsland: `Prince Edward Island`,
        quebec: `Quebec`,
        saskatchewan: `Saskatchewan`,
        yukon: `Yukon`,
      },
      state: `State`,
      states: {
        alabama: `Alabama`,
        alaska: `Alaska`,
        arizona: `Arizona`,
        arkansas: `Arkansas`,
        california: `California`,
        colorado: `Colorado`,
        connecticut: `Connecticut`,
        delaware: `Delaware`,
        districtOfColumbia: `District of Columbia`,
        florida: `Florida`,
        georgia: `Georgia`,
        hawaii: `Hawaii`,
        idaho: `Idaho`,
        illinois: `Illinois`,
        indiana: `Indiana`,
        iowa: `Iowa`,
        kansas: `Kansas`,
        kentucky: `Kentucky`,
        louisiana: `Louisiana`,
        maine: `Maine`,
        maryland: `Maryland`,
        massachusetts: `Massachusetts`,
        michigan: `Michigan`,
        minnesota: `Minnesota`,
        mississippi: `Mississippi`,
        missouri: `Missouri`,
        montana: `Montana`,
        nebraska: `Nebraska`,
        nevada: `Nevada`,
        newHampshire: `New Hampshire`,
        newJersey: `New Jersey`,
        newMexico: `New Mexico`,
        newYork: `New York`,
        northCarolina: `North Carolina`,
        northDakota: `North Dakota`,
        ohio: `Ohio`,
        oklahoma: `Oklahoma`,
        oregon: `Oregon`,
        pennsylvania: `Pennsylvania`,
        rhodeIsland: `Rhode Island`,
        southCarolina: `South Carolina`,
        southDakota: `South Dakota`,
        tennessee: `Tennessee`,
        texas: `Texas`,
        utah: `Utah`,
        vermont: `Vermont`,
        virginia: `Virginia`,
        washington: `Washington`,
        westVirginia: `West Virginia`,
        wisconsin: `Wisconsin`,
        wyoming: `Wyoming`,
      },
    },
    help: `Help`,
    name: {
      first: `First Name`,
      last: `Last Name`,
    },
    phone: {
      home: `Home Phone Number`,
      work: `Business Phone Number`,
      cell: `Cell Phone Number`,
    },
    email: `Email Address`,
    dateMask: `(MM/DD/YYYY)`,
    selectOne: `Select One`,
    notSelected: `Not Selected`,
    unknown: `Unknown`,
    dob: `Date of Birth`,
    yes: `Yes`,
    no: `No`,
    prev: `Previous`,
    next: `Next`,
    page: `Page`,
    of: `of`,
    edit: `Edit`,
    printPage: `Print This Page`,
    information: `Information`,
    feedback: `Feedback`,
  },
  claim: {
    headers: {
      yourInfo: `Your Information`,
      lossInfo: `Loss Information`,
      lossLocation: `Loss Location`,
      otherPartyInfo: `Other Party Information`,
      otherPartyVehicleInfo: `Other Party Vehicle Information`,
      ourInsuredInfo: `Our Insured Information`,
      driverOfInsuredVehicle: `Driver Of The Insured Vehicle`,
      ourInsuredVehicleInfo: `Our Insured Vehicle Information`,
      policeReport: `Police Report`,
      otherParties: `Other Parties`,
      passengers: `Passengers`,
      witnesses: `Witnesses`,
      mortgagee: `Mortgagee`,
    },
    intro: `Your Wawanesa Insurance policy number is required to complete this claim form.`,
    emergency: {
      text1: `If this is a claim emergency, please  call`,
      text2: `. We're available 24/7.`,
    },
    requiredField: `indicates a required field`,
    createClaimReport: {
      home: `Create a Claim Report - Residential`,
      auto: `Create a Claim Report - Automobile`,
    },
    reportingParty: {
      wawanesa: `Wawanesa Insured`,
      broker: `Broker`,
    },
    phone: {
      instructions: `Please type at least one phone number below`,
      ext: `ext.`,
    },
    yourInsuranceCompany: `Insurance Company Name`,
    yourInsurancePolicyNum: `Insurance Company Policy #`,
    lossInformation: `Loss Information`,
    policyNumber: `Our Policy Number`,
    dateOfLoss: `Date of Loss`,
    timeOfLoss: `Time of Loss`,
    streetLocation: `Street/Freeway Location`,
    describeAccident: `Describe the Accident or Incident`,
    sameAsReportingParty: `Same as Your Information`,
    sameAsInsured: `Same as insured's address`,
    driverOfInsuredVehicleQuestion: `Is The Driver Of The Insured Vehicle Same As Above?`,
    noDriver: `No Driver`,
    driversLicenseNum: `Drivers License Number`,
    injuries: `Injuries`,
    vehicle: {
      vehicle: `Vehicle`,
      year: `Vehicle Year`,
      make: `Vehicle Make`,
      model: `Vehicle Model`,
      color: `Vehicle Colour`,
      licensePlate: `License Plate`,
      vin: `VIN`,
      damages: `Vehicle Damages`,
      vehicleInfo: "Vehicle Information",
    },
    reportObtained: `Was a police report or joint report obtained?`,
    jointReport: `Joint Report`,
    noReport: `No Report`,
    policeDeptObtainedFrom: `Police force that report was obtained from:`,
    policeReportNumber: `Your police report number:`,
    howMany: `How Many?`,
    additionalOtherParties: `Are there additional Other Parties not reported above?`,
    anyOtherPassengers: `Were there any passengers in any of the involved vehicles?`,
    anyWitnesses: `Were there any witnesses?`,
    anyMortgagees: `Does the home have any other mortgagees?`,
    struckByUnknown: `Our Insured Vehicle Struck By Unknown Party`,
    otherParty: `Other Party`,
    passenger: `Passenger`,
    passengerLocation: `Location of Passenger`,
    insuredVehicle: `Insured's Vehicle`,
    witness: `Witness`,
    mortgage: `Mortgage`,
    editClaim: `Edit Claim`,
    submitClaim: `Submit Claim`,
    validation: {
      isRequired: `is required`,
      required: `Required`,
      moreThan: `must be longer than`,
      lessThan: `cannot be longer than`,
      characters: `characters`,
      invalid: `Invalid Input`,
      valid: `valid`,
      enterValid: `Enter a valid`,
      futureDate: `Date cannot be in the future`,
      invalidDate: `Invalid date format`,
      enterPhoneNumber: `Please enter at least one phone number`,
    },
    claimSummary: `Claim Summary`,
    contactInfo: `We are available 24 hours a day, 7 days a week at`,
    success: {
      submitSuccess: `Claim Successfully Submitted`,
      thankYou: `Thank you for submitting your claim online. A member of our claims team will contact you within the next business day.`,
      referenceNumber: `Please record the number below to use as reference until a claim number is confirmed for you by a member of our claims team.`,
      emergency: {
        auto: `If you are experiencing a claim emergency (i.e. you have a need for a tow or rental) please call immediately for assistance.`,
        home: `If you are experiencing a claim emergency (i.e you have a situation involving water, have been a victim of theft, have been displaced from your home, or have immediate needs related to your claim) please call immediately for assistance.`,
      },
    },
    error: {
      submitError: `Claim Submission Error`,
      thankYou: `Thank you for attempting to submit your claim online.`,
      technicalDifficulty: `Unfortunately, we have experienced a technical difficulty and we are unable to process your online claim submission.`,
      noReSubmit: `Please do not attempt to resend or submit this claim again online.`,
      callUs: `Please call us to complete your claim submission.`,
      apology: `We apologize for any inconvenience.`,
    },
  },
  fab: {
    titleBase: `Canada Car, Homeowners, Farm & Commercial Insurance | `,
    header: `Find a Broker`,
    directory: {
      title: `Broker Directory`,
      backToMap: `Back to Map`,
      backToDirectory: `Back to Directory`,
      filter: {
        instructions: `Filter your results by broker name or address`,
        text: `Broker name or address`,
      },
      results: `Results`,
      profile: `Broker Profile`,
      noResults: `No results found`,
    },
    links: {
      viewBrokers: `View Broker Directory`,
      // The following 3 translations are for QC Clic-Assure links which are only needed in French
      // Including them so the tests don't fail
      autoInsurance: ``,
      homeInsurance: ``,
    },
    warning: ``,
    details: {
      searchLocationName: `Search by location or name`,
      fromYourLocation: ` from your location`,
      directions: `Directions`,
      email: `Email`,
      resultsInYourArea: `results in your area`,
      pleaseEnterAtLeast3Letters: `Please type in at least 3 letters to begin search`,
    },
    fabUnavailable: `This feature is temporarily unavailable.`,
    tryAgainLater: `We apologize for the inconvenience. Please try again later.`,
    noBrokers: `There are no brokers in view`,
    enterNewSearch: `Please enter a new search term or adjust your zoom level to try again.`,
    refineSearch: `Refine your search`,
    adjustZoom: `Enter a location above or zoom in using the map to find a broker.`,
    fabInstructions: `Looking for a quote or need more information about insurance coverage? Use our simple tool to find a broker near you.`,
  },
  layout: {
    nav: {
      fab: `Find a Broker`,
      countrySelect: {
        choose: `Choose your region`,
        canada: `Wawanesa Canada`,
        life: `Wawanesa Life`,
        california: `Wawanesa California`,
        oregon: `Wawanesa Oregon`,
        home: `Wawanesa Home`,
      },
      products: {
        main: `Products`,
        auto: `Auto Coverage`,
        home: `Home Coverage`,
        condo: `Condo Coverage`,
        tenant: `Tenant Coverage`,
        farm: `Farm Coverage`,
        commercial: `Commercial Business Coverage`,
        life: `Life Insurance`,
      },
      claims: {
        main: `Claims`,
        auto: `Submit Automobile Claim`,
        home: `Submit Residential Claim`,
        farm: `Submit a Farm Claim`,
        info: `Claim Information`,
      },
      payments: {
        main: `Payments & Billing`,
        makeAPayment: `Make a Payment`,
        options: `Payment Options`,
        info: `Payment & Billing Information`,
      },
      about: {
        main: `About Us`,
        mission: 'Our Purpose, Vision and Values',
        news: 'News Centre',
        info: 'Company Information',
        history: 'History of Wawanesa',
        charity: 'Charitable Giving',
        finances: 'Financial Ratings',
        reports: 'Annual Reports',
      },
      contact: {
        main: `Contact Us`,
        general: `General Inquiries`,
        jobs: `Jobs`,
        offices: `Branch and Service Offices`,
        media: `Media Inquiries`,
        customerCare: `Customer Care`,
        inquiries: `Inquiries and Feedback`,
        faq: `Frequently Asked Questions`,
        concerns: `Concerns and Complaints`,
        socialMedia: `Social Media Guidelines`,
      },
      blog: `Blog`,
      search: `Search`,
    },
    footer: {
      discover: `Discover Wawanesa`,
      aboutUs: `About Us`,
      privacyPolicy: `Privacy Policy`,
      contactUs: `Contact Us`,
      copyright1: `Copyright 1996-`,
      copyright2: `Wawanesa Insurance`,
      termsAndConditions: `Terms & Conditions`,
      brokerPortal: `Broker Portal`,
      blu: `blu`,
    },
  },
}

export default en

import React from 'react'
import { useHistory } from 'react-router-dom'
import { BASE_URL } from '../../../utils/consts'

/**
 * @summary Turns an html string into a component. Also listens for click events and routes any
 *  links with a specified prefix through react-router.
 *
 * @param {string} props.html The html code to be parsed and rendered.
 * @param {string} [props.alt = ''] If the html is unavailable, an alternate text to display.
 */
const InnerHtml = ({ html, alt = '' }) => {
  const history = useHistory()

  /**
   * @summary Listens for click events in html and appends links with /web/canada to router history.
   *
   * @listens InnerHtml:onClick
   * @param {event} e Click Event.
   */
  const htmlClickHandler = (e) => {
    const targetLink = e.target.closest('a')

    //  return if the click event is not an anchor tag, has no href attribute (menu toggles)
    //  or does not start with /web/canada
    if (targetLink && targetLink.getAttribute('href') && targetLink.getAttribute('href').startsWith(BASE_URL)) {
      // anchor tags starting with /web/canada are routed using react-router-dom
      e.preventDefault()
      const path = e.target.getAttribute('href').replace(BASE_URL, '')

      history.push(path)
    }
  }

  let innerHtml
  if (html) {
    innerHtml = (
      // eslint-disable-next-line max-len
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
        onClick={htmlClickHandler}
      />
    )
  } else {
    innerHtml = alt
  }

  return innerHtml
}

export default InnerHtml

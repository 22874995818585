import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import WaitFor from '../../components/common/ui/WaitFor'
import useStyleImport from '../../utils/hooks/useStyleImport'
import { BASE_URL, ENDPOINTS } from '../../utils/consts'
import { LangContext } from '../../localization/localizationContext'
import Header from '../../components/layout/layout-parts/Header'
import Footer from '../../components/layout/layout-parts/Footer'

const stylesheets = [
  `${BASE_URL}/resources/css/loader.css`,
  `${BASE_URL}/resources/css/wawanesa.css`,
  `${BASE_URL}/resources/css/_wawanesa.css`,
  `${BASE_URL}/resources/css/bulma.css`,
  {
    href: 'https://use.fontawesome.com/releases/v5.3.1/css/all.css',
    integrity: 'sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU',
    crossOrigin: 'anonymous',
  },
]

const Layout = ({ children }) => {
  const { lang } = useContext(LangContext)
  const { styles, isLoading: stylesAreLoading } = useStyleImport(stylesheets)
  const [navIsLoading, setNavIsLoading] = useState(true)
  const [nav, setNav] = useState([])

  useEffect(() => {
    setNavIsLoading(true)
    const getNavigation = () => (
      axios.get(`${ENDPOINTS.NAVIGATION}?language_id=${lang.ID}`)
    )

    getNavigation()
      .then((res) => setNav(res.data))
      .catch((err) => {
        console.error(err.message)
        // Retrying the call if the first one fails for some reason
        getNavigation().then((res) => setNav(res.data))
      })
      .finally(() => setNavIsLoading(false))
  }, [lang.ID])

  return (
    <>
      {styles}
      <WaitFor isLoading={stylesAreLoading || navIsLoading}>
        <Header nav={nav} />
        <section className="section">
          <div className="page-content">
            {children}
          </div>
        </section>
        <Footer />
      </WaitFor>
    </>
  )
}

export default Layout

import React, { useState, useEffect, useContext, useRef } from 'react'
import ScrollArea from 'react-scrollbar/dist/no-css'
import { LangContext } from '../../../../localization/localizationContext'
import useAddressPredictions from '../../../../utils/hooks/useAddressPredictions'
import { centreCompare, searchBrokers } from '../../../../utils/mapUtility'
import { FAB_VIEW } from '../../../../utils/consts'
import Result from './Result'
import LookAheadSearchResults from './LookAheadSearchResults'
import UnavailableErrorText from './UnavailableErrorText'

/**
 * @summary Loads the scrollable search pane for the Find A Broker page
 *
 * @param {array.<object>} props.brokerData Collection of broker data from DB
 * @param {boolean} atAcceptableZoom If the map is at a zoom level that should display brokers
 * @param {object} props.mapCenter Lat/Lng coordinates for the map center
 * @param {object} props.selectedPinLoc Lat/Lng coordinates for currently selected pin
 * @param {boolean} props.viewBrokersLoaded If the brokers pins have been loaded in the map
 * @param {array.<object>} props.visibleBrokers Collection of brokers currently visible on the map
 * @param {function} props.handleSelectedPin Handler for selecting a broker
 * @param {function} props.handleUpdateCenter Handler for updating the new center of the map
 */
// eslint-disable-next-line max-len
const BrokerDetails = ({ brokerData, atAcceptableZoom, mapCenter, selectedBrokerCode, viewBrokersLoaded, visibleBrokers, handleSelectedPin, handleUpdateCenter, brokerCommError }) => {
  const { translate } = useContext(LangContext)
  const [searchTerm, setSearchTerm] = useState('')
  const [showLookAhead, setShowLookAhead] = useState(false)
  const [brokerPredictions, setBrokerPredictions] = useState([])
  const [selectedPrediction, setSelectedPrediction] = useState(null)
  const { predictions, getCoordinates } = useAddressPredictions(searchTerm)
  const addressPredictions = predictions.slice(0, 4)
  const searchRef = useRef()
  const predictionsRef = useRef()
  const searchTermLength = searchTerm.trim().length
  const showNoResultsFound = (visibleBrokers.length) === 0 && viewBrokersLoaded && !showLookAhead

  useEffect(() => {
    if (searchTerm.trim().length >= 3) {
      const searchResults = searchBrokers(brokerData, searchTerm)
      const brokersClosestToMapCenter = searchResults.sort(centreCompare(mapCenter)).slice(0, 4)
      setBrokerPredictions(brokersClosestToMapCenter)
    }
  }, [searchTerm, brokerData, mapCenter])

  /**
   * Sets up event listeners to hide look ahead when clicking outside of the box
   */
  useEffect(() => {
    const handleClickOutsidePredictions = (e) => {
      if (predictionsRef.current) {
        if (!predictionsRef.current.contains(e.target)) {
          setShowLookAhead(false)
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutsidePredictions)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsidePredictions)
    }
  }, [searchRef, predictionsRef])

  /**
   * @description Run on pin selection
   */
  const handleUpdateCenterHelper = (coords, code) => {
    setShowLookAhead(false)
    handleSelectedPin(coords, code)
    handleUpdateCenter(coords, code)
  }

  /**
   * @description Gets geographic coordinates for brokers or google lookahead results. Fetching
   *  Google coordinates are asynchronous.
   *
   * @param {int} index Index of desired look ahead search result
   * @returns {object} Lat/Lng coordinates
   */
  const getPinCoordinates = async (index) => {
    const selected = brokerPredictions.concat(addressPredictions)[index]
    const isBroker = Boolean(selected.code)
    if (isBroker) {
      return selected.position
    } else {
      const coords = await getCoordinates(selected.place_id)
      return coords
    }
  }

  /**
   * @description Gets the broker code if we selected a broker.
   *
   * @param {int} index Index of desired look ahead search result
   * @returns {object} Broker code or empty string
   */
  const getBrokerCode = (index) => {
    const selected = brokerPredictions.concat(addressPredictions)[index]
    const isBroker = Boolean(selected.code)
    if (isBroker) {
      return selected.code
    } else {
      return ''
    }
  }

  /**
   * @description Run on look ahead selection
   *
   * @param {int} index Index of desired look ahead search result
   */
  const handlePredictionSelection = async (index) => {
    const coords = await getPinCoordinates(index)
    const code = getBrokerCode(index)
    setShowLookAhead(false)
    handleUpdateCenterHelper(coords, code)
  }

  /**
   * @description Handles keyboard events like navigating and selecting lookahead results
   */
  const handleKeyDown = (e) => {
    const { key } = e
    const predictionsLength = brokerPredictions.length + addressPredictions.length
    if (key === 'ArrowUp' || key === 'ArrowDown') {
      e.preventDefault()
      if (key === 'ArrowUp') {
        if (selectedPrediction === 0) {
          searchRef.current.focus()
        } else {
          setSelectedPrediction((prev) => prev - 1)
        }
      } else if (key === 'ArrowDown' && selectedPrediction < predictionsLength - 1) {
        setSelectedPrediction((prev) => (prev !== null ? prev + 1 : 0))
      }
    } else if (key === 'Enter' && selectedPrediction === null && predictionsLength > 0) {
      handlePredictionSelection(0)
      searchRef.current.blur()
    // event.key provides a "descriptive" title a key. For characters it will just be the character
    } else if (key.length === 1) {
      searchRef.current.focus()
    }
  }

  /**
   * @description Checks if the provided broker is currently selected
   *
   * @param {object} result Broker entry
   */
  const checkActive = (result) => selectedBrokerCode === result.code

  const searchText = (searchTermLength > 0 && searchTermLength < 3) && (
    <div className="ww-list-count">
      {translate('fab.details.pleaseEnterAtLeast3Letters')}
    </div>
  )

  const noResultsFound = (
    <p className="ww-list-noresults">
      <span>{translate('fab.noBrokers')}</span>
      <br />
      {translate('fab.enterNewSearch')}
    </p>
  )

  const zoomedOutTooFar = (
    <p className="ww-list-noresults align:left">
      <span>{translate('fab.refineSearch')}</span>
      <br />
      {translate('fab.adjustZoom')}
    </p>
  )

  const brokerCommErrorText = (
    <UnavailableErrorText center={1} />
  )

  let errorText

  if (brokerCommError) {
    errorText = brokerCommErrorText
  } else if (showNoResultsFound) {
    errorText = noResultsFound
  } else if (!atAcceptableZoom) {
    errorText = zoomedOutTooFar
  }

  return (
    <div className="ww-fab-listing">
      <div className="field">
        <div className="control ww-fab-input" ref={predictionsRef}>
          <input
            type="text"
            size={50}
            placeholder={translate('fab.details.searchLocationName')}
            className="input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            ref={searchRef}
            onFocus={() => {
              setShowLookAhead(true)
              setSelectedPrediction(null)
            }}
          />
          {showLookAhead && (
            <LookAheadSearchResults
              addresses={addressPredictions}
              brokers={brokerPredictions}
              getCoordinates={getCoordinates}
              hide={searchTerm.length < 3}
              selectedPrediction={selectedPrediction}
              handleClick={(index) => handlePredictionSelection(index)}
              handleKeyDown={handleKeyDown}
            />
          )}
        </div>
      </div>
      {searchText}
      <ScrollArea
        className="scrollarea"
        contentClassName="content"
        speed={0.8}
        smoothScrolling
        minScrollSize={10}
        horizontal={false}
      >
        {errorText}
        {atAcceptableZoom && visibleBrokers.map((result) => (
          <Result
            key={result.code}
            broker={result}
            highlighted={checkActive(result)}
            handleUpdateCenter={handleUpdateCenterHelper}
            viewType={FAB_VIEW.MAP}
          />
        ))}
      </ScrollArea>
    </div>
  )
}

export default BrokerDetails

import React, { useContext } from 'react'
import { LangContext } from '../../../../localization/localizationContext'
import Result from '../../../../components/views/broker/find-a-broker/Result'
import { Link, useParams } from 'react-router-dom'
import { REACT_APP_GOOGLE_API_KEY, FAB_VIEW, ROUTES } from '../../../../utils/consts'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import logo from '../../../../assets/img/ww-marker.png'
import mapStyle from '../find-a-broker/mapStyle.json'
import UnavailableErrorText from '../find-a-broker/UnavailableErrorText'

require('dotenv').config()

const libraries = ['places']

const BrokerProfile = ({ brokers, brokerCommError }) => {
  const { translate, lang } = useContext(LangContext)
  const { code } = useParams()
  const broker = brokers.find((e) => e.code === code)

  return (
    <div>
      <div className="header-w-buttons is-directory">
        <div>
          <div className="header-title">
            <h1>{translate('fab.directory.profile')}</h1>
          </div>
          <Link to={ROUTES.BROKER_DIRECTORY} className="button is-small is-link">
            {translate('fab.directory.backToDirectory')}
          </Link>
        </div>
      </div>
      {brokerCommError && (
        <UnavailableErrorText />
      )}
      <div className="ww-fab-directory">
        {broker && (
          <Result
            key={broker.code}
            broker={broker}
            highlighted={false}
            handleUpdateCenter={undefined}
            viewType={FAB_VIEW.PROFILE}
          />
        )}
      </div>
      <div className="ww-profile-map">
        {broker && (
          <LoadScript
            googleMapsApiKey={REACT_APP_GOOGLE_API_KEY}
            language={lang.CODE}
            libraries={libraries}
          >
            <GoogleMap
              options={{
                styles: mapStyle,
              }}
              mapContainerStyle={{
                width: '300px',
                height: '300px',
              }}
              center={broker.position}
              zoom={16}
            >
              <Marker
                key={broker.code}
                title={broker.name}
                position={broker.position}
                icon={logo}
              />
            </GoogleMap>
          </LoadScript>
        )}
      </div>
    </div>
  )
}

export default BrokerProfile

import React, { useContext } from 'react'
import { LangContext } from '../../../localization/localizationContext'
import wawanesaInsuranceGrey from '../../../assets/img/Wawanesa_Insurance_grey.svg'
import wawanesaAssuranceGrey from '../../../assets/img/Wawanesa_Assurance_grey.svg'
import bestsIcon from '../../../assets/img/Blue_A_SM.png'
import InnerHtml from '../../../components/common/utility/InnerHtml'
import socialIcons from '../../../components/layout/layout-parts/social-icons'
import LayoutLink from '../../../components/layout/layout-parts/LayoutLink'

const footerNav = [
  {
    text: 'layout.nav.products.main',
    items: [
      {
        href: '/canada/products/automobile-insurance.html',
        text: 'layout.nav.products.auto',
      },
      {
        href: '/canada/products/home-insurance.html',
        text: 'layout.nav.products.home',
      },
      {
        href: '/canada/products/farm-insurance.html',
        text: 'layout.nav.products.farm',
      },
      {
        href: '/canada/products/business-insurance.html',
        text: 'layout.nav.products.commercial',
      },
      {
        href: '/canada/products/life-insurance.html',
        text: 'layout.nav.products.life',
      },
    ],
  },
  {
    text: 'layout.nav.contact.customerCare',
    items: [
      {
        href: '/canada/contact-us/general-inquiries/offices.html',
        text: 'layout.nav.contact.main',
      },
      {
        href: '/canada/contact-us/customer-care/frequently-asked-questions',
        text: 'layout.nav.contact.faq',
      },
      {
        href: '/canada/contact-us/customer-care/inquiriesandfeedback',
        text: 'layout.nav.contact.inquiries',
      },
      {
        href: '/canada/contact-us/customer-care/concernsandcomplaints',
        text: 'layout.nav.contact.concerns',
      },
    ],
  },
  {
    text: 'layout.footer.discover',
    items: [
      {
        href: 'https://jobs.wawanesa.com/',
        text: 'layout.nav.contact.jobs',
      },
      {
        href: '/canada/blog/blog-posts.html',
        text: 'layout.nav.blog',
      },
      {
        href: '/canada/news-listing.html',
        text: 'layout.nav.about.news',
      },
      {
        href: '/home/history/index.html',
        text: 'layout.nav.about.history',
      },
      {
        href: '/canada/about-us/about-mission.html',
        text: 'layout.nav.about.mission',
      },
      {
        href: '/canada/about-us/about-company.html',
        text: 'layout.nav.about.info',
      },
    ],
  },
]

const Footer = () => {
  const { translate, lang } = useContext(LangContext)
  const currentYear = new Date().getFullYear()

  const getFooterColumn = (column) => {
    const items = column.items.map((item) => (
      <li key={item.text}>
        <LayoutLink
          href={item.href}
        >
          {translate(item.text)}
        </LayoutLink>
      </li>
    ))

    return (
      <div className="column" key={column.text}>
        <h4>{translate(column.text)}</h4>
        <ul>
          {items}
        </ul>
      </div>
    )
  }

  const getFooterNav = (navItems) => {
    const columns = navItems.map((column) => getFooterColumn(column))

    return (
      <div className="column is-three-quarters">
        <div className="columns">
          {columns}
        </div>
      </div>
    )
  }

  return (
    <footer className="footer ww-footer">
      <div className="ww-footer-one">
        <div className="container">
          <div className="columns">
            {getFooterNav(footerNav)}
            <div className="column ww-footer-logo">
              <img
                alt={translate('wawanesa')}
                src={lang.CODE === 'fr' ? wawanesaAssuranceGrey : wawanesaInsuranceGrey}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="ww-footer-two">
        <div className="container">
          <div className="columns">
            <div className="column">
              <p className="is-size-7">
                {`${translate('layout.footer.copyright1') + currentYear} ${translate('layout.footer.copyright2')}`}
              </p>
              <p>
                <LayoutLink
                  href="/canada/about-us/about-financial.html"
                  attributes={{ className: 'ww-ambest' }}
                >
                  <img id="logoAMBest" alt="AM Best" src={bestsIcon} />
                </LayoutLink>
              </p>
            </div>
            <div className="column is-three-quarters ww-footer-links">
              <p className="is-size-7">
                <LayoutLink href="/canada/pip/about-privacy.html">
                  {translate('layout.footer.privacyPolicy')}
                </LayoutLink>
                <span className="ww-text-divider" />
                <LayoutLink href="/canada/pip/terms-and-conditions.html">
                  {translate('layout.footer.termsAndConditions')}
                </LayoutLink>
                <span className="ww-text-divider" />
                <LayoutLink href="https://brokerportal-signin.wawanesa.com/portal/sso/login">
                  {translate('layout.footer.brokerPortal')}
                </LayoutLink>
                <span className="ww-text-divider" />
                <LayoutLink href="https://wmic.sharepoint.com/sites/blu/en/Pages/default.aspx">
                  {translate('layout.footer.blu')}
                </LayoutLink>
              </p>
              <InnerHtml html={socialIcons} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'


/** ****************************************** NOTE ******************************************
 * For this hook to work, the Google Maps API will need to have been loaded into the app with
 * the places library param. This is done in this app by the LoadScript component in BrokerMap.js
 * https://developers.google.com/maps/documentation/javascript/places
 ******************************************************************************************* */
const useAddressPredictions = (input, locationBias) => {
  const [predictions, setPredictions] = useState([])
  const autocomplete = useRef()
  const geocoder = useRef()
  let location = locationBias

  if ((!autocomplete.current || !geocoder.current) && window.google) {
    autocomplete.current = new window.google.maps.places.AutocompleteService()
    geocoder.current = new window.google.maps.Geocoder()
  }

  if (!location) {
    window.navigator.geolocation.getCurrentPosition((success) => {
      location = { lat: success.coords.latitude, lng: success.coords.longitude }
    })
  }

  const getPlacePredictions = (search) => {
    const trimmedSearch = search.trim()
    if (trimmedSearch && trimmedSearch.length >= 3) {
      const request = {
        input: trimmedSearch,
        componentRestrictions: {
          country: 'ca',
        },
        types: ['geocode'],
      }
      // Setting location bias if location is available
      if (location) {
        request.location = new window.google.maps.LatLng(location.lat, location.lng)
        request.radius = 1
      }
      autocomplete.current.getPlacePredictions(request, (addressPredictions) => {
        // Typing really fast may cause address predictions to be null, return empty array in case
        setPredictions(addressPredictions || [])
      })
    }
  }

  const getCoordinates = async (placeId) => {
    const request = { placeId }
    return new Promise((resolve, reject) => {
      geocoder.current.geocode(request, async (responses, status) => {
        if (status === 'OK') {
          resolve(responses[0].geometry.location)
        }
        reject(new Error(status))
      })
    })
  }

  const debouncedGetPlacePredictions = useCallback(
    debounce(getPlacePredictions, 500),
    [],
  )

  useEffect(() => {
    debouncedGetPlacePredictions(input)
  }, [input, debouncedGetPlacePredictions])

  return { predictions, getCoordinates }
}

export default useAddressPredictions

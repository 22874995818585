import React, { useContext } from 'react'
import { LangContext } from '../../../localization/localizationContext'
import { REACT_APP_SITE } from '../../../utils/consts'

const LayoutLink = ({ attributes, href, children }) => {
  const { lang, translate } = useContext(LangContext)

  const getFullLink = () => {
    if (href.startsWith('/')) {
      return `${REACT_APP_SITE}${href.replace(':lang', lang.CODE.toUpperCase())}?language_id=${lang.ID}`
    } else if (href.startsWith('#')) {
      return href
    } else {
      return `${href}?locale=${translate('locale')}`
    }
  }

  return <a href={getFullLink()} {...attributes}>{children}</a>
}

export default LayoutLink

import React, { useContext, useState, useEffect } from 'react'
import { LangContext } from '../../../../localization/localizationContext'
import { Link, useHistory } from 'react-router-dom'
import { FAB_VIEW, ROUTES } from '../../../../utils/consts'
import Pagination from '../../../../components/common/ui/Pagination'
import Result from '../../../../components/views/broker/find-a-broker/Result'
import { searchBrokers } from '../../../../utils/mapUtility'
import UnavailableErrorText from '../find-a-broker/UnavailableErrorText'

const BrokerDirectory = ({ brokers, brokerCommError }) => {
  const { translate } = useContext(LangContext)
  const [directoryBrokers, setDirectoryBrokers] = useState(brokers)
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const history = useHistory()
  const totalPages = Math.ceil(directoryBrokers.length / itemsPerPage)

  document.title = translate('fab.links.viewBrokers')

  // Setting the initial page number based off the url param
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const paramPage = parseInt(urlParams.get('page'), 10)
    if (paramPage >= 1 && paramPage <= totalPages) {
      setCurrentPage(paramPage)
    }
  }, [totalPages])

  // Ensuring the current page can never exceed total pages
  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages || 1)
    }
  }, [currentPage, totalPages])

  // Rewriting the url on page change
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set('page', currentPage)
    // eslint-disable-next-line prefer-template
    history.replace(history.location.pathname + '?' + urlParams.toString())
  }, [currentPage, history])

  useEffect(() => {
    if (searchTerm.trim().length >= 3) {
      setDirectoryBrokers(searchBrokers(brokers, searchTerm))
    } else {
      setDirectoryBrokers(brokers)
    }
  }, [searchTerm, brokers])

  const brokerList = directoryBrokers.slice(indexOfFirstItem, indexOfLastItem).map((b) => (
    <Result
      key={b.code}
      broker={b}
      viewType={FAB_VIEW.BROKER_DIRECTORY}
    />
  ))

  let helperText
  if (searchTerm.length < 3) {
    helperText = translate('fab.details.pleaseEnterAtLeast3Letters')
  } else if (directoryBrokers.length === 0) {
    helperText = translate('fab.directory.noResults')
  } else {
    helperText = `${directoryBrokers.length} ${translate('fab.directory.results').toLowerCase()}`
  }

  return (
    <>
      <div className="header-w-buttons is-directory">
        <div>
          <div className="header-title">
            <h1>{translate('fab.directory.title')}</h1>
          </div>
          <Link to={ROUTES.FIND_A_BROKER} className="button is-small is-link">
            {translate('fab.directory.backToMap')}
          </Link>
        </div>
      </div>

      <div className="ww-fab-directory">
        {brokerCommError && (
          <>
            <UnavailableErrorText />
          </>
        )}
        {brokerCommError === false && (
          <>
            <p>{translate('fab.directory.filter.instructions')}</p>
            <div className="ww-fa-directory-search">
              <div className="field">
                <div className="control is-expanded ww-fab-input">
                  <input
                    type="text"
                    size={50}
                    placeholder={translate('fab.directory.filter.text')}
                    className="input"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {searchTerm.length > 0 && <div className="ww-list-count">{helperText}</div>}
                </div>
                <div className="control">
                  <a className="button is-info ww-fab-search">
                    {translate('layout.nav.search')}
                  </a>
                </div>
              </div>
            </div>
            <div className="ww-fa-directory-results">
              <h3>{translate('fab.directory.results')}</h3>
              {brokerList}
            </div>
            <Pagination
              current={currentPage}
              pageSize={itemsPerPage}
              total={directoryBrokers.length}
              onChange={setCurrentPage}
            />
          </>
          )}
      </div>
    </>
  )
}

export default BrokerDirectory

import { useContext, useEffect } from 'react'
import { LangContext } from '../../localization/localizationContext'

/**
 * @summary When rendered this component switches the app language and redirects to the last page.
 *
 * @description This functionality is abstracted to its own component due to the header be loaded
 *   as external html. The header allows the setting of an url that is tied to the language switch
 *   button via an inverse path parameter. We have it set to a route that renders this component.
 */
const SwitchLanguage = () => {
  const { toggleLang } = useContext(LangContext)

  useEffect(() => {
    toggleLang()
  }, [toggleLang])

  return null
}

export default SwitchLanguage

/**
 * @fileOverview Contains functions with no dependencies used in different parts of the application
 *
 * @author James Giesbrecht
 */

/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */

/**
 * @summary Access a value in a object using a dot notation string
 *
 * @example
 * const john = {
 *   occupation: {
 *     title: 'developer',
 *     yearsExp: 3
 *   },
 *   address: {
 *     street: {
 *       number: '12',
 *       name: 'Centre St.'
 *     }
 *   }
 * }
 * const key = 'address.street'
 * const result = getProperty(john, key) // result is { number: '12', name: 'Centre St.' }
 *
 * @param {object} obj Object that contains the property.
 * @param {string} key Dot notation string that references a property within the object. Includes
 *  support for arrays.
 *
 * @returns {(object|*)} The last object/property specified in the string, undefined if not found.
 */
export const getProperty = (obj, key) => {
  let i = 0
  key = key.split('.')
  while (obj && i < key.length) {
    const nextKey = key[i]
    if (nextKey.includes('[') && nextKey.includes(']')) {
      const index = nextKey.substring(nextKey.indexOf('[') + 1, nextKey.indexOf(']'))
      const keyWithoutIndex = nextKey.split('[')[0]
      try {
        obj = obj[keyWithoutIndex][index]
      } catch (e) {
        //  Inputs have not yet been registered by react-hook-form (first render cycle)
        obj = obj[keyWithoutIndex]
      }
    } else {
      obj = obj[nextKey]
    }
    i++
  }
  return obj
}

/**
 * @summary Inserts a string at a specified index in another string.
 *
 * @param {string} str String to modify.
 * @param {string} newSubStr String to be inserted.
 * @param {*} insertIndex Starting index of insertion. A negative number can be supplied to start
 *  counting from the end of the string.
 */
export const insertAtIndex = (str, newSubStr, insertIndex) => (
  str.slice(0, insertIndex) + newSubStr + str.slice(insertIndex)
)

/**
 * @summary Prepends a specified string to the name field of an object.
 * @description All inputs in a form must have a unique name property.
 *   This allows reuse of commonly used inputs in a form.
 *
 * @param {object} field Form input containing a name field.
 * @param {string} name String that is to be prepended to the name field.
 * @param {number} [insertAt=0] The index at which the string should be inserted.
 *
 * @returns {object} The same object with the name field modified.
 */
//  TODO: Refactor
export const prependName = (field, name, insertAt = 0) => {
  if (insertAt === 0) name += '.'
  if (field.name) {
    field = {
      ...field,
      name: insertAtIndex(field.name, name, insertAt),
    }
  }

  if (field.append && field.append.name) {
    field.append = {
      ...field.append,
      name: insertAtIndex(field.append.name, name, insertAt),
    }
  }

  if (field.conditional) {
    const newConditional = {}
    const keys = Object.keys(field.conditional)
    for (const key of keys) {
      newConditional[insertAtIndex(key, name, insertAt)] = field.conditional[key]
    }

    field = {
      ...field,
      conditional: newConditional,
    }
  }

  if (field.watch) {
    const newWatch = {}
    const keys = Object.keys(field.watch)
    for (const key of keys) {
      newWatch[insertAtIndex(key, name, insertAt)] = insertAtIndex(field.watch[key], name, insertAt)
    }

    field = {
      ...field,
      watch: newWatch,
    }
  }

  if (field.set) {
    const newSet = {}
    const keys = Object.keys(field.set)
    for (const key of keys) {
      newSet[insertAtIndex(key, name, insertAt)] = field.set[key]
    }

    field = {
      ...field,
      set: newSet,
    }
  }

  if (field.generate) {
    //  Checking if we are prepending a name or if we are inserting into the [] brackets
    const nestedName = insertAt === 0 ? `${name}${field.generate.name}[]` : name
    const newSubInputs = field.generate.inputs.map((subField) => (
      //  Recursively modifying the name field of sub forms
      prependName(subField, nestedName, insertAt)
    ))
    const newGenerate = {
      ...field.generate,
      inputs: newSubInputs,
    }
    field = {
      ...field,
      generate: newGenerate,
    }
  }

  return field
}

/**
 * @summary Drills down the first path in an object to find a key.
 * @description Used for finding an error key in a variably nested object.
 *
 * @param {object} obj Object containing the key.
 * @param {string} key Name of the wanted key.
 * @param {number} [maxDepth=5] Optional, maximum depth to go to prevent an infinite loop.
 *
 * @returns {object|*} Value corresponding to the key or undefined if key is not found.
 */
/* TODO: Refactor to find the keys that are not nested under the first path
 *  Not "needed" right now but would be nice to have
 * EX: This method would not be able to find key3
 * {
 *   key1: 'one',
 *   key2: {
 *     key3: 'three'
 *   }
 * }
 */
export const findFirstKey = (obj, key, maxDepth = 10) => {
  if (typeof obj === 'object' && !(obj instanceof Array) && Object.keys(obj).length > 0) {
    for (let i = 0; i < maxDepth; ++i) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        return obj[key]
      }
      obj = obj[Object.keys(obj)[0]]
    }
  } else {
    throw new Error(`Expected: Object\nReceived: ${typeof obj}`)
  }
  return undefined
}

/**
 * @summary Checks if a date is in the future.
 *
 * @param {string} date In any format readable by Date.parse().
 *
 * @returns {(boolean|string)} True if date is in past or is empty, error message otherwise.
 */
export const isNotFuture = (date) => {
  //  For cases where date is not required
  if (date === '') {
    return true
  }
  const today = new Date()
  date = new Date(date)
  if (date.getTime() > today.getTime()) {
    return 'claim.validation.futureDate'
  }
  if (Number.isNaN(date.getTime())) {
    return 'claim.validation.invalidDate'
  }
  return true
}

/**
 * @summary Checks if string is empty.
 *
 * @param {string} string String to check.
 * @param {string} message Message to return if string is empty.
 *
 * @returns {(boolean|string)} True if not empty, supplied message if it is.
 */
export const checkNext = (string, message) => (
  (typeof string === 'string' && string !== '') ? true : message
)

/**
 * @summary Compare two arrays to see if they're equal, does not take order into consideration.
 *  Contents of array must be primitive types.
 *
 * @param {array} arr1 First array.
 * @param {array} arr2 Second array.
 * @param {string} [parent=null] Optional, when comparing arrays in nested objects you can
 *   supply the parent node name to assist with debugging.
 * @returns {(boolean|string)} True if arrays are equal, error message otherwise.
 */
export const arraysEqual = (arr1, arr2, parent = '') => {
  //  To assist with debugging by specifying where the error is
  if (parent !== '') { parent = ` at node '${parent}'` }
  if (arr1 === arr2) { return true }
  if (arr1 == null || arr2 == null) { return 'Expected: array\nReceived: null' }
  if (arr1.length !== arr2.length) return `Length mismatch${parent}\n${arr1}\n${arr2}`

  arr1.sort()
  arr2.sort()
  //  Compare each item in sorted arrays for equality
  for (const [i, item1] of arr1.entries()) {
    const item2 = arr2[i]
    if (item1 !== item2) {
      return `Name mismatch${parent}\nPotentially at '${item1}' and/or '${item2}'`
    }
  }
  return true
}

/**
 * @summary Recursive function to compare two objects to see if each key has a match.
 *
 * @param {object} obj1 First object.
 * @param {object} obj2 Second object.
 * @param {string} [parent='root'] Optional, the name of the current node to assist with debugging.
 * @param {function} [checkEqual] Only here so jest can mock this function. DO NOT PASS IN.
 * @returns {(boolean|string)} True if objects are equal, error message otherwise.
 */
export const compareKeys = (obj1, obj2, parent = 'root') => {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)
  let isEqual = arraysEqual(keys1, keys2, parent)
  if (isEqual === true) {
    for (const key of keys1) {
      if (typeof obj1[key] === 'object') {
        isEqual = compareKeys(obj1[key], obj2[key], key)
        if (isEqual !== true) return isEqual
      }
    }
  }
  return isEqual
}

/**
* @summary escapes regex characters and returns the pure string
*
* @param {string} [string] string with characters to be escaped
* @returns {string} The pure string without special characters
*/
export const escapeRegExp = (string) => (
  string.replace(/[.*+,\-?^${}()|[\]\\]/g, '')
)

/**
 * @description Check if current userAgent is IE
 * @returns {boolean}
 */
export const isIE = () => {
  const ua = window.navigator.userAgent
  return ua.indexOf('Trident/7.0') > -1 || ua.indexOf('Trident/6.0') > -1 || ua.indexOf('Trident/5.0') > -1
}

import React, { useContext } from 'react'
import { LangContext } from '../../../localization/localizationContext'

const Pagination = ({ current = 1, pageSize = 10, total, onChange }) => {
  const totalPages = Math.ceil(total / pageSize)
  const { translate } = useContext(LangContext)

  const prevPage = () => { if (current > 1) onChange(current - 1) }
  const nextPage = () => { if (current < totalPages) onChange(current + 1) }

  const getPageNumber = (pageNumber) => {
    if (!pageNumber) return <li key={Math.random()}><span className="pagination-ellipsis">…</span></li>
    const isCurrent = pageNumber === current

    const aria = { 'aria-label': `Goto page ${pageNumber}` }
    // Conditionally adding the aria-current attribute as it cannot be done in JSX
    if (isCurrent) aria['aria-current'] = 'page'

    return (
      <li key={pageNumber}>
        <a
          {...aria}
          className={`pagination-link${isCurrent ? ' is-current' : ''}`}
          role="button"
          tabIndex={0}
          onClick={() => onChange(pageNumber)}
          onKeyDown={(e) => { if (e.key === 'Enter') onChange(pageNumber) }}
        >
          {pageNumber}
        </a>
      </li>
    )
  }

  const pageNumbers = []
  if (totalPages <= 7) {
    let i = 1
    do {
      pageNumbers.push(i)
      i += 1
    } while (i <= totalPages)
  } else if (current >= totalPages - 2) {
    pageNumbers.push(1, null, totalPages - 3, totalPages - 2, totalPages - 1, totalPages)
  } else if (current <= 4) {
    pageNumbers.push(1, 2, 3, 4, 5, null, totalPages)
  } else {
    pageNumbers.push(1, null, current - 1, current, current + 1, null, totalPages)
  }


  return (
    <nav className="pagination ww-results-pagination" role="navigation" aria-label="pagination">
      <a
        aria-label="Previous"
        role="button"
        tabIndex={0}
        disabled={current === 1}
        onClick={prevPage}
        onKeyDown={(e) => { if (e.key === 'Enter') prevPage() }}
        className="pagination-previous"
      >
        {translate('common.prev')}
      </a>
      <a
        aria-label="Next"
        role="button"
        tabIndex={0}
        disabled={current >= totalPages}
        onClick={nextPage}
        onKeyDown={(e) => { if (e.key === 'Enter') nextPage() }}
        className="pagination-next"
      >
        {translate('common.next')}
      </a>
      <ul className="pagination-list">
        {pageNumbers.map((n) => getPageNumber(n))}
      </ul>
    </nav>
  )
}

export default Pagination

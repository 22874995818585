import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { LangContext } from '../../../../localization/localizationContext'
import { ROUTES } from '../../../../utils/consts'
import BrokerMap from './BrokerMap'
import BrokerDetails from './BrokerDetails'

const getRandomNumberInRange = (min, max) => (
  Math.random() * (max - min + 1) + min
)

// Random location ranging from south-west Saskatchewan to north-west Ontario. This is to ensure
// that the same broker is not always populating the top spot when location is not available
const defaultLocation = {
  lat: getRandomNumberInRange(49, 53),
  lng: getRandomNumberInRange(-107, -89),
}

/**
 * @summary Loads and initializes state for the main map page of Find a Broker
 */
const FindABroker = ({ brokers, brokerCommError }) => {
  const { translate, lang } = useContext(LangContext)
  const [viewBrokersLoaded, setViewBrokersLoaded] = useState(false)
  const [mapCenter, setMapCenter] = useState(defaultLocation)
  const [visibleBrokers, setVisibleBrokers] = useState([])
  const [zoom, setZoom] = useState(3)
  const [selectedBrokerCode, setSelectedBrokerCode] = useState('')
  const atAcceptableZoom = zoom > 3

  document.title = translate('fab.titleBase') + translate('fab.header')

  // Gets the users Geolocation (if available) and fetches the broker data from express
  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition((success) => {
      const currentLocation = { lat: success.coords.latitude, lng: success.coords.longitude }
      setMapCenter(currentLocation)
      setZoom(15)
    })
  }, [])

  const handleSelectedPin = (pinLoc, code) => {
    // Checking if the selected broker is in the list pane, re-centering if not
    if (!visibleBrokers.find((b) => b.code === code)) setMapCenter(pinLoc)
    setSelectedBrokerCode(code)
  }

  const handleUpdateCenter = (newCentre) => {
    setMapCenter(newCentre)
    setZoom(15)
  }

  const handleZoomChanged = (newZoom) => {
    setZoom(newZoom)
  }

  const clicAssureButtons = (
    <>
      <a
        href="https://www.clickinsurance.ca/v2/assurance/wawanesa-auto-quebec?culture=fr-CA&code=WAWA"
        className="button is-small is-link"
      >
        <i className="fas fa-external-link-alt" />
        {translate('fab.links.autoInsurance')}
      </a>
      <a
        href="https://www.clickinsurance.ca/v2/assurance/wawanesa-habitation-quebec?culture=fr-CA&code=WAWA"
        className="button is-small is-link"
      >
        <i className="fas fa-external-link-alt" />
        {translate('fab.links.homeInsurance')}
      </a>
    </>
  )

  const clicAssureText = (
    <div className="buttonDisclaimer">
      {translate('fab.warning')}
    </div>
  )

  return (
    <>
      <div className="header-w-buttons">
        <div className={`header-title${lang.CODE === 'fr' ? ' fr' : ''}`}>
          <h1>{translate('fab.header')}</h1>
          <div>{translate('fab.fabInstructions')}</div>
        </div>
        <div className="header-buttons">
          <div>
            {lang.CODE === 'fr' && clicAssureButtons}
            <Link to={ROUTES.BROKER_DIRECTORY} className="button is-small is-link">
              {translate('fab.links.viewBrokers')}
            </Link>
          </div>
          {lang.CODE === 'fr' && clicAssureText}
        </div>
      </div>
      <div className="ww-fab-box" id="main-page">
        <BrokerDetails
          brokerData={brokers}
          mapCenter={mapCenter}
          atAcceptableZoom={atAcceptableZoom}
          selectedBrokerCode={selectedBrokerCode}
          viewBrokersLoaded={viewBrokersLoaded}
          visibleBrokers={visibleBrokers}
          handleSelectedPin={handleSelectedPin}
          handleUpdateCenter={handleUpdateCenter}
          brokerCommError={brokerCommError}
        />
        <BrokerMap
          center={mapCenter}
          atAcceptableZoom={atAcceptableZoom}
          markers={brokers}
          zoom={zoom}
          selectedBrokerCode={selectedBrokerCode}
          handleSelectedPin={handleSelectedPin}
          handleUpdateBrokers={setVisibleBrokers}
          handleZoomChanged={handleZoomChanged}
          setMapCenter={setMapCenter}
          setViewBrokersLoaded={setViewBrokersLoaded}
        />
      </div>
    </>
  )
}

export default FindABroker

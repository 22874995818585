import React, { useEffect, useState, useContext } from 'react'
import { LangContext } from '../../../../localization/localizationContext'
import { GoogleMap, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api'
import logo from '../../../../assets/img/ww-marker.png'
import logoSelected from '../../../../assets/img/ww-marker-selected.png'
import { REACT_APP_GOOGLE_API_KEY, BASE_URL } from '../../../../utils/consts'
import { centreCompare } from '../../../../utils/mapUtility'
import mapStyle from './mapStyle.json'

const desktopMapHeight = '600px'
const mobileMapHeight = '300px'
const brokerPinCount = 50

const libraries = ['places']

// eslint-disable-next-line max-len
const BrokerMap = ({ center, atAcceptableZoom, markers, zoom, selectedBrokerCode, handleSelectedPin, handleUpdateBrokers, handleZoomChanged, setMapCenter, setViewBrokersLoaded }) => {
  const { lang } = useContext(LangContext)
  const [map, setMap] = useState()
  // eslint-disable-next-line max-len
  const [mapHeight, setMapHeight] = useState(window.innerWidth <= 725 ? mobileMapHeight : desktopMapHeight)
  const [mapMarkers, setMapMarkers] = useState([])

  useEffect(() => {
    const updateDimensions = () => {
      // Mobile view: update things here...
      if (window.innerWidth <= 725) {
        setMapHeight(mobileMapHeight)
      } else {
        setMapHeight(desktopMapHeight)
      }
    }

    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const boundsChanged = () => {
    setMapCenter(map.getCenter())
    const bounds = map.getBounds()
    const brokersInView = markers.filter((m) => (
      bounds.contains(m.position)
    )).sort(centreCompare(center))
    const newViewBrokers = brokersInView.slice(0, 15)
    handleUpdateBrokers(newViewBrokers)
    setMapMarkers(brokersInView.slice(0, brokerPinCount))
    handleZoomChanged(map.getZoom())
    setViewBrokersLoaded(true)
  }

  const renderMarker = (m, newLogo, newLogoSelected, clusterer) => {
    const bounds = map.getBounds()
    if (bounds && bounds.contains(m.position)) {
      return (
        <Marker
          key={m.code}
          title={m.name}
          position={m.position}
          icon={m.code === selectedBrokerCode ? newLogoSelected : newLogo}
          clusterer={clusterer}
          onClick={() => handleSelectedPin(m.position, m.code)}
        />
      )
    }
    return null
  }
  const path = `${BASE_URL}/resources/img/m`
  const options = {
    styles: [1, 2, 3, 4, 5].map((d) => ({
      url: `${path}${d}.png`,
      textColor: 'white',
      height: 55,
      width: 55,
      anchorText: [-3, -3],
    })),
    maxZoom: 14,
  }
  return (
    <div className="ww-fab-map">
      <LoadScript
        googleMapsApiKey={REACT_APP_GOOGLE_API_KEY}
        language={lang.CODE}
        libraries={libraries}
      >
        <GoogleMap
          options={{
            styles: mapStyle,
          }}
          mapContainerStyle={{
            width: '700px',
            height: mapHeight,
          }}
          center={center}
          zoom={zoom}
          onIdle={boundsChanged}
          onLoad={setMap}
        >
          {atAcceptableZoom && (
            <MarkerClusterer options={options}>
              {(clusterer) => mapMarkers.map((m) => (
                (renderMarker(m, logo, logoSelected, clusterer))
              ))}
            </MarkerClusterer>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

export default BrokerMap
